import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Link } from 'gatsby';
import Typography from '@material-ui/core/Typography';
import classNames from 'classnames';
import {
  useUserIPGrabber,
  styleConfig,
} from '@cleverrealestate/clever-helpers';
import withMUI from '../utils/MUI.utils';
import Layout from '../components/layout';
import SEO from '../components/Seo';
import ThankYouBuyBg from '../images/img_comps/backgrounds/ThankYouBuyBg';
import MortgageLender from '../images/img_comps/backgrounds/MortgageLender';
import TaxDeferred from '../images/img_comps/backgrounds/TaxDeferred';
import PackingFamilyBlueBox from '../images/img_comps/backgrounds/PackingFamilyBlueBox';
import SellTaxFree from '../images/img_comps/backgrounds/SellTaxFree';
import SavingOnCommissionSmall from '../images/img_comps/backgrounds/SavingOnCommissionSmall';
import LowAgentCommission from '../images/img_comps/backgrounds/LowAgentCommission';

const useStyles = makeStyles(theme => ({
  thankYouPage: {
    ...theme.mixins.layout.column.base,
    position: 'relative',
    backgroundColor: 'white',
    alignItems: 'center',
  },
  bgPhoto: {
    width: '100%',
    position: 'absolute',
    top: '0',
    left: '0',
    '@media screen and (max-width: 1580px)': {
      width: '1580px',
      left: '50%',
      transform: 'translateX(-50%)',
      overflow: 'hidden',
    },
  },
  heroElementsWrapper: {
    ...theme.mixins.layout.column.base,
    ...theme.mixins.topBarAlign,
    height: 'calc((564/1920 * 100vw) - 125px)',
    position: 'relative',
    alignItems: 'center',
    maxWidth: '100%',
    marginTop: '125px',
    color: 'white',
    overflowY: 'hidden',
    '@media screen and (max-width: 1580px)': {
      height: 'calc((564/1920 * 1580px) - 125px)',
    },
  },
  heroIconWrapper: {
    marginBottom: '25px',
  },
  heroTextWrapper: {
    ...theme.mixins.noSelect,
    color: 'white',
    textAlign: 'center',
    maxWidth: '700px',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      maxWidth: '100%',
    },
    [`@media screen and (max-width: ${styleConfig.media.mobileSmall}px)`]: {
      '& h4': {
        fontSize: '1.2214285714285714rem',
      },
      '& h5': {
        fontSize: '1.1214285714285714rem',
      },
    },
  },
  heroText: {
    maxWidth: '100%',
    textAlign: 'center',
  },
  heroSubText: {
    maxWidth: '598px',
    textAlign: 'center',
    fontWeight: '500',
    margin: 'auto',
    borderBottom: '1px solid white',
    paddingBottom: '20px',
    marginBottom: '20px',
    [`@media screen and (max-width: ${styleConfig.media.mobile}px)`]: {
      maxWidth: '100%',
    },
  },
  heroDescription: {
    '& a': {
      color: 'white',
      textDecoration: 'none',
      fontWeight: '500',
    },
  },
  whiteBackground: {
    padding: '60px 0',
    maxWidth: '1164px',
    width: '100%',
    margin: 'auto',
  },
  additionalResources: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    '@media screen and (max-width: 1152px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  resource: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    textAlign: 'center',
    padding: '0 20px',
    width: '50%',
    minHeight: '400px',
    '@media screen and (max-width: 1152px)': {
      width: 'auto',
      maxWidth: '480px',
      margin: '20px',
      padding: '20px 0',
    },
    '& h3': {
      fontSize: '1em',
      fontWeight: '500',
      maxWidth: '290px',
      marginBottom: '10px',

      '& span': {
        color: theme.mixins.color.cleverBlue,
      },
    },
    '& p': {
      padding: '0 36px',
      width: '100%',
    },
  },
  additionalResourceTitle: {
    width: '100%',
    fontSize: '1.4em',
    marginBottom: '30px',
    padding: '0 20px',
  },
  blueButton: {
    marginTop: '20px',
    backgroundColor: theme.mixins.color.cleverBlue,
    color: 'white',
    padding: '10px 15px',
    borderRadius: '20px',
    fontSize: '0.8em',
    fontWeight: '500',
    textDecoration: 'none',
  },
  borderRight: {
    borderRight: '1px solid lightgray',
    '@media screen and (max-width: 1152px)': {
      borderRight: '0',
      borderBottom: '1px solid lightgray',
    },
  },
  imageWrapper: {
    width: '50%',
  },
  fullWidth: {
    width: '100%',
    height: 'auto',
    maxHeight: '320px',
    position: 'relative',
    overflow: 'hidden',
    '@media screen and (max-width: 1152px)': {
      height: '420px',
      maxHeight: '420px',
      '& .gatsby-image-wrapper': {
        height: '100%',
      },
    },
  },
  whatsNext: {
    padding: '20px 20px',
    display: 'flex',
    maxWidth: '1164px',
    flexDirection: 'column',
    position: 'absolute',
    margin: 'auto',
    top: '50%',
    left: '0',
    right: '0',
    color: 'white',
    width: '50%',
    transform: 'translateY(-50%)',
    '@media screen and (max-width: 1152px)': {
      width: 'auto',
    },
    '& h3': {
      fontSize: '1.3em',
    },
    '& ol': {
      padding: '0 20px',
      '& li': {
        padding: '10px 0',
      },
    },
  },
  learnMoreContainer: {
    maxWidth: '1164px',
    display: 'flex',
    width: 'auto',
    margin: 'auto',
    flexDirection: 'row',
    flexWrap: 'wrap',
    padding: '60px 20px',
    justifyContent: 'space-between',
    '@media screen and (max-width: 920px)': {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cleverBlogTitle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '20px',
    '& a': {
      width: '100%',
      textDecoration: 'none',
      color: theme.mixins.color.cleverBlue,
    },
  },
  learnMoreContent: {
    width: 'calc(33% - 10px)',
    display: 'flex',
    flexDirection: 'column',
    '@media screen and (max-width: 920px)': {
      maxWidth: '600px',
      width: '100%',
      marginBottom: '20px',
    },
  },
  fullWidthLarge: {
    height: 'auto',
    width: '100%',
  },
  blogContent: {
    color: '#58595b',
    textDecoration: 'none',
    '& h5': {
      padding: '10px 0',
      fontSize: '1em',
      borderBottom: '1px solid lightgray',
    },
    '& p': {
      padding: '10px 0',
    },
    '&:hover': {
      color: theme.mixins.color.cleverBlue,
    },
  },
}));

const ThankYouForSigningUp = () => {
  const classes = useStyles();
  useUserIPGrabber();

  return (
    <Layout
      colorTransitionStart={50}
      shadowTransitionStart={50}
      colorTransitionLength={100}
      shadowTransitionLength={100}
      hideContactUs
    >
      <SEO
        pathname="/thanks-for-signing-up-2/"
        title="Clever | Thank You for Signing Up - AutoHunt"
        description={'Additional Resources Did you know ⅓ of all homes can be sold tax-deferred?'
          + ' If your home is an investment property, second home, vacation rental, and more,'
          + ' it might be eligible to sell tax free through a 1031 exchange if you buy another'
          + ' property. Clever has partnered with 1031x, a top rated exchange provider'}
        meta={[{
          name: 'robots',
          content: 'noindex',
        }]}
      />
      <div className={classes.thankYouPage}>
        <div className={classes.bgPhoto}>
          <ThankYouBuyBg />
        </div>

        <div className={classes.heroElementsWrapper}>
          <div className={classes.heroTextWrapper}>
            <Typography className={classes.heroText} variant="h4" color="inherit" gutterBottom>
              THANKS FOR SIGNING UP!
            </Typography>
            <Typography className={classes.heroSubText} variant="h6" color="inherit" gutterBottom>
              Your agent will be reaching out shortly
            </Typography>
            <Typography className={classes.heroDescription} variant="body1" color="inherit">
              You will be receiving an introduction by the method you chose. If you have
              any questions, feel free to call us at
              <a href="tel://8332253837"> 833-2-CLEVER (833-225-3837)</a>
            </Typography>
          </div>
        </div>
        <div className={classes.whiteBackground}>
          <Typography variant="h2" className={classes.additionalResourceTitle}>
            Additional Resources
          </Typography>
          <div className={classes.additionalResources}>
            <div className={classNames([classes.resource, classes.borderRight])}>
              <Typography variant="h3">
                Did you know ⅓ of all homes can be sold <span>tax deferred?</span>
              </Typography>
              <div className={classes.imageWrapper}>
                <TaxDeferred />
              </div>
              <Typography variant="body1">
                If your home is an investment property, second home, vacation rental, and more,
                it might be eligible to sell tax free through a 1031 exchange if you buy another
                property. Clever has partnered with Asset Preservation Inc, a top rated exchange
                provider to offer this service at a discount.
              </Typography>
              <a
                href="https://go.listwithclever.com/1031-exchange/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                className={classes.blueButton}
              >
                LEARN MORE
              </a>
            </div>
            <div className={classes.resource}>
              <Typography variant="h3">
                Need A Top-Rated <span>Mortgage Lender</span>?
              </Typography>
              <div className={classes.imageWrapper}>
                <MortgageLender />
              </div>
              <Typography variant="body1">
                Work with Movement Mortgage, a top rated lender with over 6,000 reviews
                and a 4.97 rating. They offer personal service and competitive rates,
                and local lenders in all 50 states.
              </Typography>
              <a
                href={'https://urldefense.proofpoint.com/v2/url?u=https-3A__move-2Dwith-2D'
                  + 'clever-2Dmm.secure-2Dclix.com&d=DwMFaQ&c=nrD0w5fSBGq_engwic45yw&r=goa'
                  + '-DWvPyhAjbXLR38lNjiHp7485mTIRZ3ovMoVYH8A&m=F54-FlkBmEW59brwAONzIXC1D8'
                  + '-WaQ6KQavVThN_1Ew&s=vaPzt0R69tRmzlp6qCl1pXiOH-0nuUBklTSP_-mcw-w&e='}
                target="_blank"
                rel="noopener noreferrer nofollow"
                className={classes.blueButton}
              >
                LEARN MORE
              </a>
            </div>
          </div>
        </div>
        <div className={classes.fullWidth}>
          <PackingFamilyBlueBox />
          <div className={classes.whatsNext}>
            <Typography variant="h3">
              SO WHAT&apos;S NEXT?
            </Typography>
            <ol>
              <li>Our team is getting you connected with a top local
                agent who has experience in your neighborhood and price range.
              </li>
              <li>Your agent will schedule a time to meet with you and provide
                a Comparative Market Analysis (CMA) of your home. If you’re buying
                a home your agent will send you every home for sale that matches your
                criteria.
              </li>
              <li>Your local agent will be with you all the way to closing, to ensure
                a simple and stress free transaction.
              </li>
            </ol>
          </div>
        </div>
        <div className={classes.fullWidthLarge}>
          <div className={classes.learnMoreContainer}>
            <Typography variant="h5" className={classes.cleverBlogTitle}>
              Want to learn more?
              <Link to="/real-estate-blog/">Read the Clever Blog</Link>
            </Typography>
            <div className={classes.learnMoreContent}>
              <Link to="/what-is-a-1031-exchange-a-complete-guide/" className={classes.blogContent}>
                <SellTaxFree />
                <Typography variant="h5">
                  Complete Guide to 1031 Exchange Rules
                </Typography>
                <Typography variant="body1">
                  A 1031 exchange allows an investor to sell their investment property and
                  defer capital gains taxes as well as depreciation…
                </Typography>
              </Link>
            </div>
            <div className={classes.learnMoreContent}>
              <Link to="/real-estate-blog/save-on-realtor-commission-fees/" className={classes.blogContent}>
                <SavingOnCommissionSmall />
                <Typography variant="h5">
                  How to Save on Realtor Commission
                </Typography>
                <Typography variant="body1">
                  Selling a home can be an expensive endeavor. The average cost of selling
                  a home in the US — which…
                </Typography>
              </Link>
            </div>
            <div className={classes.learnMoreContent}>
              <Link to="/low-commission-real-estate-agents/" className={classes.blogContent}>
                <LowAgentCommission />
                <Typography variant="h5">
                  Low-Commission Real Estate
                </Typography>
                <Typography variant="body1">
                  Real estate agents usually aren’t paid a base salary. Instead, the vast majority
                  work on commission, i.e., a set percentage of…
                </Typography>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default withMUI(ThankYouForSigningUp);
