import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const TaxDeferred = () => (
  <StaticQuery
    query={graphql`
      query {
        taxDeferred: file(relativePath: { eq: "background/TaxDeferred.jpg" }) {
          childImageSharp {
            gatsbyImageData(height: 375, quality: 90)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.taxDeferred.childImageSharp.gatsbyImageData} alt="Tax Deferred" />}
  />
);
export default TaxDeferred;
