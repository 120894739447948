import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const ThankYouBuyBg = () => (
  <StaticQuery
    query={graphql`
      query {
        bg: file(relativePath: { eq: "background/ThankYouBuyBg.jpg" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, height: 564, quality: 90)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.bg.childImageSharp.gatsbyImageData} alt="Thank You Buy Background" />}
  />
);
export default ThankYouBuyBg;
