import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';

const MortgageLender = () => (
  <StaticQuery
    query={graphql`
      query {
        mortgage: file(relativePath: { eq: "background/MortgageLender.png" }) {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, height: 375, quality: 90)
          }
        }
      }
    `}
    render={data => <GatsbyImage image={data.mortgage.childImageSharp.gatsbyImageData} alt="Mortgage Lender" />}
  />
);
export default MortgageLender;
